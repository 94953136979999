h1, p {
  --typography-spacing-vertical: 1rem;
}

.divSelect {
  grid-area: 1 / 1 / 2 / 2;
}

.divSelectImage {
  grid-area: 1 / 2 / 2 / 3;
}

#select-target-image, #offrate1-image, #offrate2-image {
  height: 75px;
  width: 75px;
}

.parent2 {
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  width: 570px;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 1rem;
  display: grid;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}

.div2 {
  grid-area: 1 / 2 / 2 / 3;
}

.div3 {
  grid-area: 1 / 3 / 2 / 4;
}

.div4 {
  grid-area: 1 / 4 / 2 / 5;
}

.div5 {
  grid-area: 1 / 5 / 2 / 6;
}

.div6 {
  grid-area: 2 / 1 / 3 / 2;
}

.div7 {
  grid-area: 2 / 2 / 3 / 3;
}

.div8 {
  grid-area: 2 / 3 / 3 / 4;
}

.div9 {
  grid-area: 2 / 4 / 3 / 5;
}

.div10 {
  grid-area: 2 / 5 / 3 / 6;
}

.itemDisplay {
  height: 100px;
  width: 100px;
  background-color: #3a3a3a;
  border: .3rem solid;
  margin-top: .2rem;
  margin-bottom: .2rem;
}

.twoStarWeapon {
  border-color: #72b872;
}

.threeStarWeapon {
  border-color: #3c71bb;
}

.cogs, .exp, .overclock, .constructShard, .fourStarEquipment, .fourStarWeapon {
  border-color: #cb68c2;
}

.aConstruct, .bConstruct, .bOrAConstruct, .fiveStarWeapon {
  border-color: #ff8d1c;
}

.sConstruct, .sixStarWeapon, .transcendant, .nierConstruct {
  border-color: #fb5824;
}

@media only screen and (max-width: 500px) {
  .parent2 {
    width: 420px;
  }

  .itemDisplay {
    height: 75px;
    width: 75px;
  }
}

@media only screen and (max-width: 700px) {
  .parent2 {
    width: 500px;
  }

  .itemDisplay {
    height: 80px;
    width: 80px;
  }
}

/*# sourceMappingURL=index.c3095d71.css.map */
