/* Blue-grey Light scheme (Default) */
/* Can be forced with data-theme="light" */
/* [data-theme="light"],
:root:not([data-theme="dark"]) {
  --background-color: #1c1c1c;
  --primary: #546e7a;
  --primary-hover: #455a64;
  --primary-focus: rgba(84, 110, 122, 0.125);
  --primary-inverse: #FFF;
} */

h1, p {
  --typography-spacing-vertical: 1rem;
}

.divSelect { grid-area: 1 / 1 / 2 / 2; }
.divSelectImage { grid-area: 1 / 2 / 2 / 3; }

#select-target-image, #offrate1-image, #offrate2-image{
  height: 75px;
  width: 75px;
}

.parent2 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  width: 570px;
  margin-bottom: 1rem;
}
  
.div1 { grid-area: 1 / 1 / 2 / 2; }
.div2 { grid-area: 1 / 2 / 2 / 3; }
.div3 { grid-area: 1 / 3 / 2 / 4; }
.div4 { grid-area: 1 / 4 / 2 / 5; }
.div5 { grid-area: 1 / 5 / 2 / 6; }
.div6 { grid-area: 2 / 1 / 3 / 2; }
.div7 { grid-area: 2 / 2 / 3 / 3; }
.div8 { grid-area: 2 / 3 / 3 / 4; }
.div9 { grid-area: 2 / 4 / 3 / 5; }
.div10 { grid-area: 2 / 5 / 3 / 6; } 

.itemDisplay {
  border: 0.3rem solid;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  height: 100px;
  width: 100px;
  background-color: #3a3a3a;
}

.twoStarWeapon {
  border-color: #72b872;
}

.threeStarWeapon {
  border-color: #3c71bb;
}

.cogs, .exp, .overclock, .constructShard, .fourStarEquipment, .fourStarWeapon {
  border-color: #cb68c2;
}

.aConstruct, .bConstruct, .bOrAConstruct, .fiveStarWeapon {
  border-color: #ff8d1c;
}

.sConstruct, .sixStarWeapon, .transcendant, .nierConstruct {
  border-color: #fb5824;
}

@media only screen and (max-width: 500px) {
  .parent2 {
    width: 420px;
  }
  .itemDisplay {
    height: 75px;
    width: 75px;
  }
}

@media only screen and (max-width: 700px) {
  .parent2 {
    width: 500px;
  }
  .itemDisplay {
    height: 80px;
    width: 80px;
  }
}